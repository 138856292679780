import React from 'react'
import { Route, Redirect } from 'react-router-dom'

export const ProtectedRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        
        localStorage.getItem('accessToken') !== null ? <Component {...props} /> : <Redirect to="/login" />

        )} />
)
